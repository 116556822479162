<template>
    <ODataLookup ref="dataLookup" :dataObject="dsShareWithPersonsLkp" :whereClause="whereClause" reloadOnWhereClauseChange
        :disableRecent="noRecent" :title="$t('Person')" v-bind="$attrs" @beforeclose="clearSelection">
        <template #toolbarActions>
            <slot name="toolbarActions"></slot>
        </template>
        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.person" :ref="scope.target" style="cursor: pointer;">
                <slot name="person"></slot>
            </span>
        </template>
        <OColumn field="Name" width="250" />
        <OColumn field="Represents" width="250" />
    </ODataLookup>
</template>

<script setup lang="ts">
import { ref, onMounted} from 'vue';
import { ODataLookup } from 'o365-datalookup';
import { getDataObjectById} from 'o365-dataobject';
const dataLookup = ref(null);

const props = defineProps<{
    noRecent?: boolean,
    whereClause?: string,
    hideToggleMultiselect?: boolean
}>();
const dsShareWithPersonsLkp = getDataObjectById('dsSystem_ShareWithPersonsLookup');

function close() {
    if (dataLookup.value?.close) {
        dataLookup.value.close();
    }
}

const clearSelection = () => {
    dsShareWithPersonsLkp.selectionControl.clearSelectionsArrays()
    dsShareWithPersonsLkp.data.forEach(row => {
        if (row?.isSelected) {
            row.isSelected = false;
        }
    });
}

onMounted(()=>{
    if(props.hideToggleMultiselect){
        dsShareWithPersonsLkp.hideToggleMultiselect = true;
    } else {
        dsShareWithPersonsLkp.hideToggleMultiselect = false;
    }
})
defineExpose({ close });

</script>
